import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { navigate } from 'gatsby'
import { AREA_CARE_PATH } from '../../../config/constants'
import { Button } from '../../../common/button'

export default function Gratulation() {
  const handleSubmit = React.useCallback(async () => {
    const prevAnswersFromStorage = window.localStorage.getItem(
      'JukeboxAnswerValues'
    )
    if (prevAnswersFromStorage) {
      const prevAnswersFromStorageJSON = JSON.parse(prevAnswersFromStorage)

      let flattenPrevAnswersFromStorageJSON = {}
      Object.keys(prevAnswersFromStorageJSON).forEach((key) => {
        if (
          Array.isArray(prevAnswersFromStorageJSON[key]) &&
          typeof prevAnswersFromStorageJSON[key][0] === 'object'
        ) {
          flattenPrevAnswersFromStorageJSON = {
            ...flattenPrevAnswersFromStorageJSON,
            [key]: prevAnswersFromStorageJSON[key].map(
              (nestedObj) => nestedObj.id
            ),
          }
        } else {
          flattenPrevAnswersFromStorageJSON = {
            ...flattenPrevAnswersFromStorageJSON,
            [key]: prevAnswersFromStorageJSON[key],
          }
        }
      })

      const response = await fetch('/_cms/jukebox', {
        method: 'POST',
        body: JSON.stringify(flattenPrevAnswersFromStorageJSON),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
      const matchingResult = await response.json()

      navigate(`${AREA_CARE_PATH}/testergebnis`, {
        state: { matchingResult },
      })
    }
  }, [])

  return (
    <>
      <GlobalHelmet title="UKE jukebox - Gratulation" />
      <main className="bg-congrats">
        <Navigation />

        <header>
          <div className="row text-center mt-5">
            <div className="col-md-6 offset-md-3 col-12">
              <img
                src="/img/handlettering/handlettering-congratulations.svg"
                alt="Gratulation"
                title="Gratulation"
              />
            </div>
            <div className="col-md-6 offset-md-3 col-12">
              <h2>Du hast es geschafft</h2>
            </div>
            <div className="col-md-6 offset-md-3 col-12">
              <h4>Hier entlang und deine Stationen entdecken:</h4>
            </div>
            <div className="col-md-6 offset-md-3 col-12 mt-5">
              <Button color="red" onClick={handleSubmit}>
                Dein Ergebnis
              </Button>
            </div>
          </div>
        </header>
      </main>
    </>
  )
}
